@import "../../../assets/scss/variables.scss";

@import "../../../assets/scss/variables.scss";

.loginAdminPage {
    position: relative;
    float: left;

    .authcenter {
        width: 1600px;
        max-width: 98%;
        margin: 0 auto;
        padding-top: 0px;
        display: flex;
        align-items: center;
        height: 100%;

        .authfull {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;

            .authleftwidth {
                width: 620px;
                max-width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .findtitle {
                    margin: 0px 0 0 0;
                    font-family: $RUBIKMEDIUM;
                    font-size: $FONT_48;
                    color: $TEXT_BLUE;
                }

                .yourlogin {
                    margin: 48px 0 24px 0;
                    font-family: $MONTSERRATS_REGULAR;
                    font-size: $FONT_24;
                    color: $TEXT_DARK;
                }

                .form {
                    .textField {
                        .form-control {
                            padding-right: 50px;
                        }

                        .icon-at {
                            pointer-events: none;
                        }
                    }
                }

                .checkforgot {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .forgotlink {
                        a {
                            font-family: $MONTSERRATS_SEMIBOLD;
                            font-size: $FONT_14;
                            color: $TEXT_DARK;
                            text-decoration: none;

                            &:hover {
                                color: $TEXT_BLUE;
                            }
                        }
                    }
                }

                .submitbtn {
                    display: block;
                    margin-top: 40px;

                    .customcheck {
                        width: 80px;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        padding: 0;

                        i {
                            font-size: $FONT_26;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1300px) {
    .loginAdminPage {
        .authcenter {
            .authfull {
                .authleftwidth {
                    width: 406px;

                    .findtitle {
                        font-size: $FONT_34;
                    }

                    .yourlogin {
                        margin: 32px 0 24px 0;
                        font-size: $FONT_16;
                    }

                    .submitbtn {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1024px) {
    .loginAdminPage {
        .authcenter {
            padding-top: 0px;

            .authfull {
                .authleftwidth {
                    width: 405px;

                    .findtitle {
                        font-size: $FONT_26;
                    }

                    .yourlogin {
                        margin: 32px 0 24px 0;
                        font-size: $FONT_16;
                    }


                    .submitbtn {
                        margin-top: 40px;

                        .customcheck {
                            width: 64px;
                            height: 64px;

                            i {
                                font-size: $FONT_20;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .loginAdminPage {

        .authcenter {
            padding-top: 0px;
            max-width: 90%;

            .authfull {
                .authleftwidth {
                    margin: 0 auto;
                    width: 362px;
                    display: block;
                    float: none;

                    .findtitle {
                        font-size: $FONT_30;
                    }

                    .yourlogin {
                        margin: 32px 0 24px 0;
                        font-size: $FONT_16;
                    }

                    .submitbtn {
                        margin-top: 40px;

                        .customcheck {
                            width: 64px;
                            height: 64px;

                            i {
                                font-size: $FONT_20;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .loginAdminPage {
        .authcenter {
            padding-top: 0px;
            max-width: 90%;
            margin: 0 auto;
            display: block;

            .authfull {
                display: inline-block;
                padding: 30px 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .loginAdminPage {
        .authcenter {
            padding-top: 0px;
            max-width: 90%;
            margin: 0 auto;
            display: block;

            .authfull {
                display: block;

                .authleftwidth {
                    width: 100%;
                    margin: 0 auto;

                    .findtitle {
                        font-size: $FONT_20;
                    }

                    .yourlogin {
                        margin: 32px 0 24px 0;
                        font-size: $FONT_16;
                    }

                    .submitbtn {
                        margin-top: 40px;

                        .customcheck {
                            width: 64px;
                            height: 64px;

                            i {
                                font-size: $FONT_20;
                            }
                        }
                    }
                }
            }
        }
    }
}