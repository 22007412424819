@import "../../../assets/scss/variables.scss";

.authTextField {
  width: 100%;
  display: block;
  position: relative;
  
  .form-group {
    margin-bottom: 0px;

    &.error {
      background-color: $BG_RED;
      border-radius: 18px;

      .form-control {
        &::-webkit-input-placeholder {
          color: $TEXT_RED;
        }

        &::-moz-placeholder {
          color: $TEXT_RED;
        }

        &:-ms-input-placeholder {
          color: $TEXT_RED;
        }

        &:-moz-placeholder {
          color: $TEXT_RED;
        }
      }
    }

    .field-icon {
      color: $TEXT_LIGHTGRAY;
      position: absolute;
      top: 10px;
      right: 12px;
      font-size: $FONT_16;
      cursor: pointer;
    }
    .field-icon-disabled {
      color: $TEXT_LIGHTGRAY;
      position: absolute;
      top: 10px;
      right: 12px;
      font-size: $FONT_16;
      cursor: none;
    }

    .errorMsg {
      display: block;
      font-size: $FONT_14;
      text-align: left;
      padding: 0;
      margin: 0;
      color: $TEXT_WHITE;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease-out;
      font-family: $MONTSERRATS_REGULAR;
    }

    .errorMsg.show {
      color: $TEXT_WHITE;
      padding: 6px 10px;
      max-height: 100px;
      transition: max-height 0.3s ease-in;
      display: flex;
      align-items: flex-start;

      p {
        margin: 0;
        padding: 0;
        font-size: $FONT_14;
        color: $TEXT_WHITE;
        font-family: $MONTSERRATS_REGULAR;
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }

      &.passwordcustom {
        flex-direction: column;
        max-height: 150px;
        transition: max-height 0.5s ease-in;
        padding: 6px 10px;

        .red,
        .green {
          align-items: center;
          display: flex;
          padding: 2px 0;
          font-family: $MONTSERRATS_REGULAR;
          font-size: $FONT_14;

          span {
            position: relative;
            display: flex;
            align-items: center;

            &:before {
              position: relative;
              left: 0;
              top: 0;
              font-family: "ubikate" !important;
              margin-right: 10px;
            }
          }
        }

        .red {
          span {
            color: $TEXT_WHITE;

            &:before {
              content: "\e90b";
            }
          }
        }

        .green {
          color: $TEXT_GREEN;

          span {
            &:before {
              content: "\e90c";
            }
          }
        }
      }
    }

    .form-control {
      background: $BG_WHITE;
      font-family: $MONTSERRATS_REGULAR;
      color: $TEXT_DARK;
      font-size: $FONT_16;
      padding: 16px 24px;
      height: auto;
      outline: 0px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid $BORDER_LIGHTGRAY;
      outline: none;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-appearance: none;
      -moz-appearance: none;

      &::-webkit-input-placeholder {
        color: $TEXT_LIGHTGRAY;
      }

      &::-moz-placeholder {
        color: $TEXT_LIGHTGRAY;
      }

      &:-ms-input-placeholder {
        color: $TEXT_LIGHTGRAY;
      }

      &:-moz-placeholder {
        color: $TEXT_LIGHTGRAY;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      &.error {
        border-color: $BG_RED;
      }

      &:disabled {
        opacity: 1 !important;
        cursor: not-allowed;
      }
    }
  }
}

@media (max-width: 850px) {
  .authTextField {
    .form-group {
      // .form-control {
      //   padding: 14px 10px;
      //   padding-right: 30px;
      // }

      // .field-icon {
      //   top: 10px;
      //   right: 12px;
      // }

      // .icon-eye-on,
      // .icon-eye-off {
      //   top: 10px;
      // }
    }
  }
}
