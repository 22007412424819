// BACKGROUND COLOR
$TRANSPARENT: transparent;
$BG_WHITE: #ffffff;
$BG_RED: #f04e23;
$BG_YELLOW: #fba819;
$BG_GREEN: #006f3b;
$BG_BLUE: #1b3766;
$BG_LIGHTGRAY: #f5f3f0;
$BG_DARK: #3f3535;
$BG_DARKGREY: #505050;
$BG_GREY: #888888;
$BG_LIGHTCOLOR: #f1f3f6;
$BG_YELLOW_DARK: #ffa800;
$BG_BLUE_LIGHT: #153665;

// TEXT COLOR
$TEXT_WHITE: #ffffff;
$TEXT_BLACK: #000000;
$TEXT_RED: #f04e23;
$TEXT_YELLOW: #fba819;
$TEXT_GREEN: #006f3b;
$TEXT_BLUE: #1b3766;
$TEXT_DARK: #3f3535;
$TEXT_LIGHTGRAY: #a3a3a3;
$TEXT_DARKGREY: #505050;
$TEXT_GREY: #888888;
$TEXT_BLUE_LIGHT: #153665;
$TEXT_BLACK_LIGHT: #252733;
$TEXT_BLUE_GREY: #363740;
$TEXT_BLUE_LT: #0064ae;
$SOCIAL_ICON_TEXT: #283232;

//INPUT BORDER

$BORDER_LIGHTGRAY: #a3a3a3;
$BORDER_LIGHT: #e9ecf1;

// FONT SIZE
$FONT_8: 8px;
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_28: 28px;
$FONT_30: 30px;
$FONT_32: 32px;
$FONT_48: 48px;
$FONT_34: 34px;

// FONT FAMILY
$MONTSERRATS_LIGHT: "montserratlight";
$MONTSERRATS_REGULAR: "montserratregular";
$MONTSERRATS_MEDIUM: "montserratmedium";
$MONTSERRATS_SEMIBOLD: "montserratsemibold";
$MONTSERRATS_BOLD: "montserratbold";
$RUBIKMEDIUM: "RubikMedium";
$RUBIKSEMIBOLD: "RubikSemiBold";
$RUBIKBOLD: "RubikBold";