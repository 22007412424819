.btn_container{
    display: flex; 
    gap: 0.1rem;
    align-items: center;

    
    .btn, .divider{
        color: rgb(126, 126, 126);
    }

    .active{
        border: 2px solid rgb(255, 195, 16);
        border-radius: 50%;
    }

}


@media screen and (max-width: 850px) {
    .btn_container{
        display: flex; 
        gap: 0.1rem;
        align-items: center;
    
        
        .btn, .divider{
            color: rgb(126, 126, 126);
            padding: 5px;
        }
    
        .active{
            border: 2px solid rgb(255, 195, 16);
            border-radius: 50%;
        }
    
    }
}