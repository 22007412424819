@import "../../../assets/scss/variables.scss";

.landingPage {
  .main_container {
    display: flex;
    height: 100%;
    position: relative;
    .left {
      flex: 0.4;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: 0;
    }
    .top_mobile_border,
    .bottom_mobile_border {
      display: none;
    }
    .right {
      flex: 0.6;
      background-color: #f4f3fa;
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      padding-top: 5rem;

      .bg_element {
        aspect-ratio: 1;
        border-radius: 50%;
        // display: inline-block;

        .element_wrapper {
          width: 100%;
          height: 100%;
          position: relative;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }

          &::after {
            content: "";
            position: absolute;
            border-radius: 50%;
            aspect-ratio: 1;
          }
        }
      }

      .top_container {
        display: flex;
        justify-content: space-between;
        align-items: start;

        button {
          all: unset;
          cursor: pointer;
          padding: 0.25rem 0.75rem;
          border-radius: 5rem;
          color: white;

          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.5px;
          text-align: center;
        }

        .top_elements {
          padding: 1rem;
          flex: 0;
          display: flex;
          flex-direction: column;

          .top_image {
            margin-left: 90%;
            width: 7.5vw;

            .element_wrapper::after {
              border: 1px solid rgba(255, 0, 0, 0.6);
              width: 75%;
              bottom: 50%;
              left: 40%;
            }
          }

          .center_image {
            width: 8vw;
            margin-top: 30%;
            margin-left: 15%;
            .element_wrapper::after {
              border: 1px solid rgba(255, 0, 0, 0.6);
              width: 70%;
              left: -10%;
              bottom: 50%;
            }
          }

          .circle1 {
            width: 10vw;
            margin-top: 40%;
            margin-left: 60%;

            background-color: green;
          }
        }
        .form_container {
          max-width: 600px;
          padding: 2.5rem;
          flex: 1;
          display: flex;
          z-index: 10;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          & > * {
            width: 100%;
          }

          .hero_text {
            text-align: end;

            img {
              display: none;
            }

            h3 {
              font-size: 28px;
              font-weight: 700;
              line-height: 34.13px;
              font-family: "Montserrat", sans-serif;
              margin: 0;
              strong {
                &:nth-child(1) {
                  color: #f1a904;
                }
                &:nth-child(2) {
                  color: #028e39;
                }
                &:nth-child(3) {
                  color: #e3433e;
                }
              }
            }
            p {
              font-family: "Montserrat", sans-serif;
              font-size: 28px;
              font-weight: 400;
              line-height: 34.13px;
              text-align: right;
            }
          }

          .form_elements {
            max-width: 90%;

            .form_input_container {
              display: flex;
              flex-direction: column;
              gap: 0.75rem;

              input {
                border-radius: 20px;
                font-size: small;
                padding: 0.4rem 1rem;
                border: none;
                width: 100%;
                text-align: center;
                color: #4d4d4d;
              }

              .country_select {
                position: relative;

                &.filled {
                  input {
                    padding-left: 40px;
                  }
                }

                .flaimg {
                  position: absolute;
                  left: 15px;
                  top: 7px;
                  margin: 0;
                  z-index: 2;
                }

                .flaimg ~ div {
                  position: relative;

                  &::before {
                    position: absolute;
                    right: 14px;
                    top: 18px;
                    content: "";
                    width: 0;
                    height: 0;
                  }

                  &::before {
                    border-top: 5px solid #4d4d4d;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 0;
                  }
                }

                input ~ div {
                  z-index: 999;
                  position: absolute;
                  top: calc(100% + 1rem) !important;
                  left: 0 !important;
                }

                .country-list {
                  padding: 10px;
                  font-family: $MONTSERRATS_REGULAR;
                  font-weight: 400;
                  font-size: $FONT_14;
                  color: $TEXT_DARK;
                  z-index: 10;
                  cursor: pointer;

                  &.active,
                  &:hover {
                    background-color: $BG_BLUE;
                    color: $TEXT_WHITE;
                  }

                  span {
                    position: relative;
                    top: -1px;
                    margin-right: 5px;
                  }
                }
              }
            }

            .actions_container {
              display: flex;
              gap: 1rem;
              margin-top: 1rem;
              justify-content: space-between;
              align-items: flex-end;

              a {
                font-family: "Montserrat", sans-serif;

                font-size: 10px;
                font-weight: 400;
                line-height: 12.19px;
                text-align: center;
                color: #252d5e;
                text-decoration: underline;
              }

              .sub_actions {
                label {
                  font-size: 10px;

                  &::before {
                    width: 12px;
                    height: 12px;
                  }

                  &::after {
                    font-size: 8px;
                  }
                }
              }

              .register_btn {
                flex: 1;
                background-color: #f1a904;
              }
            }

            .text_divider {
              margin-top: 1rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1rem;
              & > div {
                flex: 1;
                height: 1px;
                border-top: 2px solid rgba(39, 39, 39, 0.703);
              }
              p {
                margin-bottom: 5px;
              }
            }

            .form_title_container {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              gap: 0.5rem;
              p {
                margin: 0;

                font-family: "Montserrat", sans-serif;

                font-size: 26px;
                font-weight: 400;
                line-height: 31.69px;
                text-align: right;

                &:nth-child(2) {
                  font-weight: 700;
                  color: #252d5e;
                  max-width: 20rem;
                  margin-left: auto;
                }
              }
              img {
                height: 60px;
              }
            }
          }
        }
        .login_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 15rem;
          margin-left: auto;
          background-color: rgb(247, 46, 46);
          z-index: 10;
        }
      }

      .social_links_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: auto;

        .social_links {
          display: flex;
          gap: 1rem;
          a {
            text-decoration: none;
            img {
              width: 2rem;
              aspect-ratio: 1;
              object-fit: contain;
            }
          }
        }
      }

      .mobile_language_toggle {
        display: none;
      }
    }
  }
}

@media (max-width: 850px) {
  .landingPage {
    .main_container {
      .right {
        .top_container {
          .top_elements {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .landingPage {
    .main_container {
      height: unset;
      background-color: white;
      padding: 5%;

      display: grid;
      place-content: center;
      .left {
        display: none;
      }
      .top_mobile_border,
      .bottom_mobile_border {
        display: block;
        position: fixed;
        left: 0;
        width: 100%;
        object-fit: contain;
      }

      .top_mobile_border {
        top: 0;
      }
      .bottom_mobile_border {
        bottom: 0;
      }

      .right {
        margin: 3rem 0;
        padding: 0;
        border-radius: 1.5rem;
        overflow: inherit;

        .top_container {
          justify-content: center;

          .form_container {
            padding: 1rem;
            flex: 1;
            display: flex;
            max-width: 100%;

            align-items: center;
            justify-content: center;

            .hero_text {
              img {
                object-fit: contain;
                height: 30px;
                margin: 1rem auto 2rem auto;
                display: block;
              }
              text-align: center;

              p {
                font-size: 24px;
                line-height: 25px;
                text-align: center;
              }
            }

            .form_elements {
              max-width: 100%;

              .actions_container {
                gap: 1rem;
                margin-top: 1rem;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;

                .register_btn {
                  width: 90%;
                }
              }

              .text_divider {
                & > div {
                  border-top: 1px solid rgba(39, 39, 39, 0.703);
                }
              }

              .form_title_container {
                flex-direction: column;
                justify-content: center;
                gap: 0.5rem;
                p {
                  margin: 0;

                  font-size: 20px;
                  font-weight: 400;
                  line-height: 31.69px;
                  text-align: center;

                  &:nth-child(2) {
                    font-weight: 700;
                    color: #252d5e;
                    max-width: 80%;
                    margin: 0 auto;
                  }
                }
                img {
                  height: 60px;
                  object-fit: contain;
                }
              }
            }
          }
          .login_btn {
            margin: 0 auto;
            margin-top: 0.5rem;
          }
        }

        .social_links_container {
          margin-bottom: 1rem;
        }

        .mobile_language_toggle {
          display: none;
        }
      }
    }
  }
}
