@import "../../../assets/scss/variables.scss";

.checkboxMain {
    position: relative;

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: 0px;
        top: 0;
        opacity: 0;
        margin: 0;
    }

    span {
        margin-left: 5px;
        position: relative;
        top: -3px;
    }

    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        padding: 0;
        margin: 0;
        cursor: pointer;
        font-family: $MONTSERRATS_SEMIBOLD;
        font-size: $FONT_14;
        color: $TEXT_DARK;
        // padding-left: 20px;
        position: relative;
        height: 16px;
        display: block;
    }

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        content: "";
        position: relative;
        left: 0;
        top: 0px;
        width: 16px;
        height: 16px;
        border: 2px solid $BG_DARK;
        border-radius: 2px;
        box-sizing: border-box;
        display: inline-block;
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        content: "\e90c";
        position: absolute;
        top: 1px;
        left: 2.8px;
        font-size: $FONT_10;
        font-weight: 600;
        font-family: "ubikate" !important;
        color: $TEXT_DARK;
    }

    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
    }

    [type="checkbox"]:checked + label::before {
        border: 2px solid $BG_BLUE;
    }

    [type="checkbox"]:checked + label::after,
    [type="checkbox"]:checked + label {
        color: $TEXT_BLUE;
    }
}
