@import "../../../assets/scss/variables.scss";

.btn {
    &.customcheck {
        background: $BG_YELLOW;
        font-weight: normal;
        color: $TEXT_WHITE;
        box-shadow: none !important;
        border: 0;
        cursor: pointer;

        &:hover {
            background: $BG_YELLOW !important;
            font-weight: normal;
            color: $TEXT_WHITE;
        }

        &:disabled {
            background: $BORDER_LIGHTGRAY;
            color: $TEXT_WHITE;
            opacity: 1 !important;
            box-shadow: none;
            cursor: not-allowed;

            &:hover {
                background: $BORDER_LIGHTGRAY !important;
                color: $TEXT_WHITE;
                cursor: not-allowed;
            }
        }

        &:active {
            background: $BG_YELLOW !important;
        }

        &:focus {
            outline: none;
            background: $BG_YELLOW !important;
        }
    }
}