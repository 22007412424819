@import "../../../assets/scss/variables.scss";

.toast {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100% !important;
    z-index: 999;
    text-align: center;
    flex-basis: inherit !important;
    margin: 0 auto !important;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 10px 0;

    .tostcenter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 620px;
        border-radius: 8px;
        padding: 12px 10px;
        margin: 0 auto;
        -webkit-box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
        -moz-box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
        box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
    }

    // &.show {
    //     opacity: 1;
    // }

    &.success-txt {
        .tostcenter {
            background: $BG_GREEN;

            i {
                margin-right: 6px;
                font-size: $FONT_16;
                color: $TEXT_WHITE;
            }

            p {
                margin: 0px;
                font-size: $FONT_16;
                line-height: 24px;
                color: $TEXT_WHITE;
            }

            a {
                color: $TEXT_WHITE;
                font-weight: 800;
            }
        }
    }

    &.error-txt {
        .tostcenter {
            background: $BG_RED;

            i {
                margin-right: 6px;
                font-size: $FONT_16;
                color: $TEXT_WHITE;
            }

            p {
                margin: 0px;
                font-size: $FONT_16;
                line-height: 24px;
                color: $TEXT_WHITE;
            }

            a {
                color: $TEXT_WHITE;
                // font-weight: 800;
            }
        }
    }

    &.warning-txt {
        .tostcenter {
            p {}
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .toast {
        .tostcenter {
            width: 490px;
        }
    }
}

@media (max-width: 767px) {
    .toast {
        .tostcenter {
            width: 320px;

            &.success-txt {
                .tostcenter {
                    padding: 12px 6px;

                    i {
                        font-size: $FONT_12;
                    }

                    p {
                        font-size: $FONT_12;
                    }
                }
            }

            &.error-txt {
                .tostcenter {
                    padding: 12px 6px;

                    i {
                        font-size: $FONT_12;
                    }

                    p {
                        font-size: $FONT_12;
                    }
                }
            }
        }
    }
}