@import "./assets/scss/variables.scss";

body {
  margin: 0px;
  padding: 0px;
  font-family: $MONTSERRATS_REGULAR;
  background-color: $BG_WHITE;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

@font-face {
  font-family: "montserratlight";
  src: url("./assets/fonts/montserrat-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/montserrat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "montserratregular";
  src: url("./assets/fonts/montserrat-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/montserrat-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "montserratmedium";
  src: url("./assets/fonts/montserrat-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/montserrat-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "montserratsemibold";
  src: url("./assets/fonts/montserrat-semibold-webfont.woff2") format("woff2"),
    url("./assets/fonts/montserrat-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "montserratbold";
  src: url("./assets/fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RubikMedium";
  src: url("./assets/fonts/RubikMedium.eot");
  src: url("./assets/fonts/RubikMedium.eot") format("embedded-opentype"),
    url("./assets/fonts/RubikMedium.woff2") format("woff2"),
    url("./assets/fonts/RubikMedium.woff") format("woff"),
    url("./assets/fonts/RubikMedium.ttf") format("truetype"),
    url("./assets/fonts/RubikMedium.svg#RubikMedium") format("svg");
}

@font-face {
  font-family: "RubikSemiBold";
  src: url("./assets/fonts/RubikSemiBold.eot");
  src: url("./assets/fonts/RubikSemiBold.eot") format("embedded-opentype"),
    url("./assets/fonts/RubikSemiBold.woff2") format("woff2"),
    url("./assets/fonts/RubikSemiBold.woff") format("woff"),
    url("./assets/fonts/RubikSemiBold.ttf") format("truetype"),
    url("./assets/fonts/RubikSemiBold.svg#RubikSemiBold") format("svg");
}

@font-face {
  font-family: "RubikBold";
  src: url("./assets/fonts/RubikBold.eot");
  src: url("./assets/fonts/RubikBold.eot") format("embedded-opentype"),
    url("./assets/fonts/RubikBold.woff2") format("woff2"),
    url("./assets/fonts/RubikBold.woff") format("woff"),
    url("./assets/fonts/RubikBold.ttf") format("truetype"),
    url("./assets/fonts/RubikBold.svg#RubikBold") format("svg");
}

@font-face {
  font-family: "ubikate";
  src: url("./assets/fonts/ubikate.eot?jtrxt2");
  src: url("./assets/fonts/ubikate.eot?jtrxt2#iefix") format("embedded-opentype"),
    url("./assets/fonts/ubikate.ttf?jtrxt2") format("truetype"),
    url("./assets/fonts/ubikate.woff?jtrxt2") format("woff"),
    url("./assets/fonts/ubikate.svg?jtrxt2#ubikate") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "FontAwesome";
  src: url("./assets/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("./assets/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
    url("./assets/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
    url("./assets/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
    url("./assets/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
    url("./assets/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ubikate" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e900";
}

.icon-close:before {
  content: "\e901";
}

.icon-send:before {
  content: "\e902";
}

.icon-droparrow:before {
  content: "\e903";
}

.icon-location:before {
  content: "\e904";
}

.icon-dropcaret:before {
  content: "\e905";
}

.icon-calendar:before {
  content: "\e906";
}

.icon-rightarrow:before {
  content: "\e907";
}

.icon-leftarrow:before {
  content: "\e908";
}

.icon-success:before {
  content: "\e909";
}

.icon-error:before {
  content: "\e90a";
}

.icon-warning:before {
  content: "\e90b";
}

.icon-check:before {
  content: "\e90c";
}

.icon-eye-on:before {
  content: "\e90d";
}

.icon-eye-off:before {
  content: "\e90e";
}

.icon-at:before {
  content: "\e90f";
}

.icon-profile:before {
  content: "\e910";
}

.icon-downarrow:before {
  content: "\e911";
}

.icon-uparrow:before {
  content: "\e912";
}

.icon-add:before {
  content: "\e913";
}

.icon-calendar1:before {
  content: "\e914";
}

.icon-menu:before {
  content: "\e915";
}

.icon-toparrow:before {
  content: "\e916";
}

.icon-caretright:before {
  content: "\e917";
}

.icon-arrow-leftdouble:before {
  content: "\e918";
}

.icon-arrow-rightdouble:before {
  content: "\e919";
}

.icon-arrow-left:before {
  content: "\e91a";
}

.icon-arrow-right:before {
  content: "\e91b";
}

.icon-sort:before {
  content: "\e91c";
}

.icon-mobile-menu:before {
  content: "\e91d";
}

.icon-edit:before {
  content: "\e91e";
}

.icon-sun:before {
  content: "\e91f";
}

.icon-half-sun:before {
  content: "\e920";
}

.icon-facebook:before {
  content: "\e921";
}

.icon-instagram:before {
  content: "\e922";
}

.icon-twitter:before {
  content: "\e923";
}

.icon-Upload:before {
  content: "\e924";
}

.icon-Trash:before {
  content: "\e925";
}

.gm-fullscreen-control {
  display: none;
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-rotate-45 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

// small black tooltip design

.tooltipSmall>div>div {
  padding: 11px 8px;
  border-radius: 6px;
  border: none;
  font-size: $FONT_12;
  font-family: $MONTSERRATS_REGULAR;
  max-width: 100%;
  width: 246px;
}

// slider global css
.mainOrangeSlider {
  padding: 14px 0;
}

.rc-slider-handle {
  border: 1px solid #fff !important;
  border-color: $TEXT_WHITE !important;
  box-shadow: none !important;

  &:hover {
    border: 1px solid #fff !important;
    border-color: $TEXT_WHITE !important;
  }

  &:active {
    box-shadow: none !important;
    border-color: $TEXT_WHITE !important;
  }
}

button {
  &:focus {
    outline: none;
  }
}

.searchInput:not(:valid)~.close-icon {
  &:after {
    content: "\e900";
    font-family: "ubikate" !important;
  }
}

.btn {
  &:focus {
    box-shadow: none !important;
  }
}

.videoModal {
  .modal-dialog {
    max-width: 1200px;
    width: 96%;

    .modal-content {
      border: 0;
      border-radius: 0;

      .modal-header {
        padding: 0;
        border-radius: 0;
        border: 0;
        display: block;
        position: relative;

        .close {
          position: absolute;
          right: 10px;
          top: 10px;
          padding: 0;
          margin: 0;
          font-size: 45px;
          color: #fff;
          opacity: 1;
        }

        iframe {
          width: 100%;
          border: 0;
        }
      }
    }
  }
}

.cookie-close {
  border: 0;
  padding: 0;
  background-color: transparent;
  margin: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -12px;
  font-size: $FONT_16;
  color: $TEXT_WHITE;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  position: relative;
  z-index: 9;
}

.loader::after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6)
}

.crrentjobmodal {}