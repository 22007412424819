@import "../../assets/scss/variables.scss";

a {
  text-decoration: none;
  color: rgb(126, 126, 126);
}

.header_container {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    margin: auto;
    nav {
      display: flex;

      ul {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        color: rgb(126, 126, 126);

        li {
          a {
            color: rgb(126, 126, 126);
          }
        }
      }
    }

    .logoContainer {
      .headerLogo {
        height: 25px;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .header_container {
    display: none;
  }
}
